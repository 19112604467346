"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.endGame = exports.markFlagged = exports.updateZeros = exports.countClosed = exports.countFlagged = exports.checkCompleted = exports.newGame = exports.fakeGame = exports.traverseNeighbours = exports.isMine = exports.Game = exports.Mine = void 0;
//@ts-ignore
const randomstring = __importStar(require("randomstring"));
class Mine {
    constructor(position, isOpened = false, bombs = 0, isFlagged = false, isQuestion = false, isDisarm = false, isBlowup = false) {
        this.position = position;
        this.isOpened = isOpened;
        this.bombs = bombs;
        this.isFlagged = isFlagged;
        this.isQuestion = isQuestion;
        this.isDisarm = isDisarm;
        this.isBlowup = isBlowup;
    }
}
exports.Mine = Mine;
class Game {
    constructor(state, totalBombs = 0, fake = false, exploded = false) {
        this.state = state;
        this.totalBombs = totalBombs;
        this.fake = fake;
        this.exploded = exploded;
        this.uuid = randomstring.generate(12);
    }
}
exports.Game = Game;
function isMine(mine) {
    return mine.bombs == -1;
}
exports.isMine = isMine;
const BOMBS_PROBABILITY = 0.15;
const dx = [-1, 0, 1, -1, 1, -1, 0, 1];
const dy = [-1, -1, -1, 0, 0, 1, 1, 1];
function traverseNeighbours(fields, startMine, onField) {
    const inBounds = (point) => point.x >= 0 && point.x < fields.length &&
        point.y >= 0 && point.y < fields[0].length;
    const start = startMine.position;
    dx.map((x, i) => ({ dx: x, dy: dy[i] }))
        .map(deltas => ({ x: start.x + deltas.dx, y: start.y + deltas.dy }))
        .filter((point) => inBounds(point))
        .map((point) => onField(fields[point.x][point.y]));
}
exports.traverseNeighbours = traverseNeighbours;
function fillBombsCount(state) {
    state.forEach((row, i) => {
        row.forEach((mine, j) => {
            if (isMine(mine)) {
                mine.bombs = -1;
                traverseNeighbours(state, mine, nf => {
                    if (!isMine(nf)) {
                        nf.bombs += 1;
                    }
                    return nf;
                });
            }
        });
    });
}
function fakeGame(rows, columns, bombs) {
    const state = Array(rows).fill(null).map((r, i) => {
        return Array(columns).fill(null).map((c, j) => {
            return new Mine({ x: i, y: j }, false, 0, false);
        });
    });
    return new Game(state, bombs, true);
}
exports.fakeGame = fakeGame;
function newGame(rows, columns, bombs, firstMine, easyStart) {
    let totalMines = 0;
    let LOCAL_BOMBS_PROBABILITY = BOMBS_PROBABILITY;
    let estimatedMines = Math.floor(rows * columns * BOMBS_PROBABILITY);
    if (bombs) {
        estimatedMines = bombs;
        LOCAL_BOMBS_PROBABILITY = estimatedMines / (rows * columns);
    }
    let excludeBombs = [];
    if (firstMine) {
        excludeBombs.push(`${firstMine.position.x}${firstMine.position.y}`);
    }
    const state = Array(rows).fill(null).map((r, i) => {
        return Array(columns).fill(null).map((c, j) => {
            const doMine = Math.random() < LOCAL_BOMBS_PROBABILITY;
            if (doMine && (excludeBombs.indexOf(`${i}${j}`) < 0)) {
                totalMines += 1;
                return new Mine({ x: i, y: j }, false, -1, false);
            }
            else {
                return new Mine({ x: i, y: j }, false, 0, false);
            }
        });
    });
    if (easyStart && firstMine) {
        traverseNeighbours(state, firstMine, (field) => {
            if (field.bombs == -1) {
                field.bombs = 0;
                totalMines -= 1;
            }
            excludeBombs.push(`${field.position.x}${field.position.y}`);
            return field;
        });
    }
    let i = 0;
    while (totalMines < estimatedMines) {
        const randX = Math.floor(Math.random() * rows);
        const randY = Math.floor(Math.random() * columns);
        if (!isMine(state[randX][randY]) && (excludeBombs.indexOf(`${randX}${randY}`) < 0)) {
            totalMines += 1;
            state[randX][randY].bombs = -1;
        }
        if ((i > (rows * columns)) && firstMine) {
            excludeBombs = [`${firstMine.position.x}${firstMine.position.y}`];
        }
        i++;
    }
    if (totalMines > estimatedMines) {
        const mines = state.map(row => row.filter(mine => isMine(mine)))
            .reduce((prev, current) => prev.concat(current));
        while (totalMines > estimatedMines) {
            const randMineIndex = Math.floor(Math.random() * mines.length);
            if (isMine(mines[randMineIndex])) {
                mines[randMineIndex].bombs = 0;
                --totalMines;
            }
        }
    }
    // let z= 0;
    // state.map(row => row.map((item: Mine) => {
    //     if (item.bombs == -1) {
    //         ++z
    //     }
    // }))
    //
    // console.log(
    //     firstMine, easyStart,
    //     totalMines, z
    // )
    fillBombsCount(state);
    return new Game(state, totalMines);
}
exports.newGame = newGame;
function isMineCovered(field) {
    if (isMine(field)) {
        return field.isFlagged;
    }
    else {
        return field.isOpened;
    }
}
function checkCompleted(game) {
    const and = (a, b) => a && b;
    return game.state.map(row => {
        return row.map(field => {
            return isMineCovered(field);
        }).reduce(and);
    }).reduce(and);
}
exports.checkCompleted = checkCompleted;
function countFlagged(game) {
    const plus = (a, b) => a + b;
    return game.state.map(row => {
        return row.map(field => {
            return field.isFlagged ? 1 : 0;
        }).reduce(plus, 0);
    }).reduce(plus, 0);
}
exports.countFlagged = countFlagged;
function countClosed(game) {
    const plus = (a, b) => a + b;
    return game.state.map(row => {
        return row.map(field => {
            return !field.isOpened ? 1 : 0;
        }).reduce(plus, 0);
    }).reduce(plus, 0);
}
exports.countClosed = countClosed;
function update(game, f, exploded = false) {
    const updated = game.state.slice().map(row => {
        return row.slice().map(field => {
            return f(field);
        });
    });
    return new Game(updated, game.totalBombs, game.exploded || exploded);
}
function updateZeros(fields, start) {
    traverseNeighbours(fields, start, (field => {
        if (!field.isOpened && !isMine(field) && !field.isFlagged) {
            field.isOpened = true;
            if (field.bombs == 0) {
                updateZeros(fields, field);
            }
        }
        return field;
    }));
}
exports.updateZeros = updateZeros;
function markFlagged(fields) {
    fields.map(row => row.map((mine) => {
        if (mine.bombs == -1) {
            mine.isFlagged = true;
        }
    }));
}
exports.markFlagged = markFlagged;
function endGame(game) {
    return update(game, (field) => {
        if (isMine(field)) {
            return new Mine(field.position, true, field.bombs, field.isFlagged);
        }
        else {
            return new Mine(field.position, field.isOpened, field.bombs, field.isFlagged);
        }
    }, true);
}
exports.endGame = endGame;
function exploreOpenedField(game, opened) {
    const updated = update(game, (field) => field);
    let hitMine = false;
    traverseNeighbours(updated.state, opened, field => {
        if (!field.isOpened && !field.isFlagged) {
            if (isMine(field)) {
                hitMine = true;
            }
            else {
                field.isOpened = true;
                if (field.bombs == 0) {
                    updateZeros(updated.state, field);
                }
            }
        }
        return field;
    });
    if (hitMine) {
        return endGame(game);
    }
    return updated;
}
function rundomGenerate() {
    const randX = Math.floor(Math.random() * 9);
    const randY = Math.floor(Math.random() * 9);
    return newGame(2, 2, 2, new Mine({ x: randX, y: randY }, false, 0, false));
}
// for (const i of new Array(1000)) {
//     console.log(
//         rundomGenerate()
//     )
// }
