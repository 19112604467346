"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepUp = void 0;
const ng = window.angular;
function StepUp($timeout) {
    return {
        restrict: 'A',
        scope: {
            elementId: "@stepUp",
            stepValue: "="
        },
        link: ($scope, $element) => {
            let Unbind;
            $timeout(() => {
                const input = document.getElementById($scope.elementId);
                if (input) {
                    const $modelCtrl = ng.element(input).controller('ngModel');
                    Unbind = $element.on('click', () => {
                        const value = parseInt($scope.stepValue);
                        $modelCtrl.$setViewValue($modelCtrl.$modelValue + value);
                        $modelCtrl.$render();
                    });
                }
            });
            $scope.$on("$destroy", () => {
                Unbind ? Unbind() : null;
            });
        }
    };
}
exports.StepUp = StepUp;
StepUp.$inject = ['$timeout'];
