"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const mines_1 = require("./controllers/mines");
const alert_1 = require("@src/app/components/alert");
const longPress_1 = require("@games/mines/src/directives/longPress");
const step_1 = require("@games/mines/src/directives/step");
const appModule = ng.module('app');
appModule.component('gameMinesweeper', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: mines_1.MinesweeperCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
class CustomComponentCtrl extends alert_1.AlertComponentCtrl {
    $onInit() {
        super.$onInit();
        this.$modalScope.$watch(() => {
            return [this.$modalScope.settings.rows, this.$modalScope.settings.columns];
        }, ([rows, columns]) => {
            if (rows && columns) {
                const x = rows * columns;
                this.$modalScope.settings.bombs = Math.floor(385.5095 + (1.50827 - 385.5095) / (1 + Math.pow(x / 1272.244, 1.36929)));
            }
        }, true);
    }
}
appModule.component('customComp', {
    bindings: {
        'modalInstance': '='
    },
    template: ['$attrs', ($attrs) => {
            return $attrs.modalTemplate ? unescape($attrs.modalTemplate) : require("./custom.ng.html");
        }],
    controller: CustomComponentCtrl,
    controllerAs: '$ctrl'
});
appModule.directive('longPress', longPress_1.LongPress);
appModule.directive('stepUp', step_1.StepUp);
appModule.config(['SoundServiceProvider', 'WsServiceProvider', 'ConfigServiceProvider', (SoundServiceProvider, WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('mines/');
        SoundServiceProvider.setSound({
            'win': require('./sounds/win.mp3').default,
            'lose': require('./sounds/lose.mp3').default,
            'flag': require('./sounds/flag.mp3').default,
            'hint': require('./sounds/hint.mp3').default,
            'disarm': require('./sounds/disarm.mp3').default,
            'blowup': require('./sounds/blowup.mp3').default,
            'area_open_cell': require('./sounds/area_open_cell.mp3').default,
            'single_open_cell': require('./sounds/single_open_cell.mp3').default,
            'question': require('./sounds/question.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            show_timer: true,
            show_number_of_cells: true,
            show_number_of_mines: true,
            show_hint: false,
            sound_effects: false,
            enable_question_mark: true,
            enable_disarm: false,
            easy_start: false,
            area_open_mode: true,
            open_remaining: true
        });
    }]);
