"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LongPress = void 0;
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
function LongPress() {
    return {
        restrict: 'A',
        //@ts-ignore
        link: ($scope, $element) => {
            const allEventDestroy = new rxjs_1.Subject();
            const touchStart$ = (0, rxjs_1.fromEvent)($element[0], 'touchstart');
            const touchEnd$ = (0, rxjs_1.fromEvent)($element[0], 'touchend');
            touchStart$.pipe((0, operators_1.mergeMap)((e) => {
                return (0, rxjs_1.of)(e).pipe((0, operators_1.delay)(500), (0, operators_1.takeUntil)(touchEnd$));
            }), (0, operators_1.takeUntil)(allEventDestroy)).subscribe(() => {
                const mouseDownEvent = new MouseEvent("mousedown", {
                    bubbles: true,
                    cancelable: false,
                    view: window,
                    button: 2,
                    buttons: 2,
                    clientX: $element[0].getBoundingClientRect().x,
                    clientY: $element[0].getBoundingClientRect().y
                });
                $element[0].dispatchEvent(mouseDownEvent);
            });
            $scope.$on("$destroy", () => {
                // console.log('popup destroy')
                allEventDestroy.next(null);
                allEventDestroy.complete();
            });
        }
    };
}
exports.LongPress = LongPress;
LongPress.$inject = [];
